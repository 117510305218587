import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Bio from '../components/Bio'
import Layout from '../components/layout/layout'
import { rhythm } from '../utils/typography'
import Hero from '../components/Hero'
import BlogPostCard from '../components/Blog/BlogPostCard'
import Pagination from '../components/Common/Pagination'

class Blog extends Component {
  renderSideBar() {
    const image = require('../assets/images/pexels-photo-529923-150x150.jpg')
    return (
      <div className="gdlr-core-pbf-sidebar-right gdlr-core-column-extend-right  bora-sidebar-area gdlr-core-column-20 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height">
        <div
          className="gdlr-core-pbf-background-wrap"
          style={{ backgroundColor: '#f7f7f7' }}
        />
        <div className="gdlr-core-sidebar-item gdlr-core-item-pdlr">
          <div id="text-5" className="widget widget_text bora-widget">
            <h3 className="bora-widget-title">Text Widget</h3>
            <div className="textwidget">
              Nulla vitae elit libero, a pharetra augue. Nulla vitae elit
              libero, a pharetra augue. Nulla vitae elit libero, a pharetra
              augue. Donec sed odio dui. Etiam porta sem malesuada.
            </div>
          </div>
          <div
            id="gdlr-core-recent-post-widget-4"
            className="widget widget_gdlr-core-recent-post-widget bora-widget"
          >
            <h3 className="bora-widget-title">Recent News</h3>
            <div className="gdlr-core-recent-post-widget-wrap gdlr-core-style-1">
              <div className="gdlr-core-recent-post-widget clearfix">
                <div className="gdlr-core-recent-post-widget-thumbnail gdlr-core-media-image">
                  <img src={image} alt width={150} height={150} />
                </div>
                <div className="gdlr-core-recent-post-widget-content">
                  <div className="gdlr-core-recent-post-widget-title">
                    <a href="#">I sink under the weight</a>
                  </div>
                  <div className="gdlr-core-recent-post-widget-info">
                    <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                      <span className="gdlr-core-head">
                        <i className="icon_clock_alt" />
                      </span>
                      <a href="#">June 6, 2016</a>
                    </span>
                    <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                      <span className="gdlr-core-head">
                        <i className="icon_documents_alt" />
                      </span>
                      <a href="#" title="Posts by John Smith" rel="author">
                        John Smith
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="gdlr-core-recent-post-widget clearfix">
                <div className="gdlr-core-recent-post-widget-thumbnail gdlr-core-media-image">
                  <img src={image} alt width={150} height={150} />
                </div>
                <div className="gdlr-core-recent-post-widget-content">
                  <div className="gdlr-core-recent-post-widget-title">
                    <a href="#">Possession of my entire soul</a>
                  </div>
                  <div className="gdlr-core-recent-post-widget-info">
                    <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                      <span className="gdlr-core-head">
                        <i className="icon_clock_alt" />
                      </span>
                      <a href="#">June 6, 2016</a>
                    </span>
                    <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                      <span className="gdlr-core-head">
                        <i className="icon_documents_alt" />
                      </span>
                      <a href="#" title="Posts by John Smith" rel="author">
                        John Smith
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="gdlr-core-recent-post-widget clearfix">
                <div className="gdlr-core-recent-post-widget-thumbnail gdlr-core-media-image">
                  <img src={image} alt width={150} height={150} />
                </div>
                <div className="gdlr-core-recent-post-widget-content">
                  <div className="gdlr-core-recent-post-widget-title">
                    <a href="#">I am so happy, my dear friend</a>
                  </div>
                  <div className="gdlr-core-recent-post-widget-info">
                    <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                      <span className="gdlr-core-head">
                        <i className="icon_clock_alt" />
                      </span>
                      <a href="#">June 6, 2016</a>
                    </span>
                    <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                      <span className="gdlr-core-head">
                        <i className="icon_documents_alt" />
                      </span>
                      <a href="#" title="Posts by John Smith" rel="author">
                        John Smith
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tag_cloud-1" className="widget widget_tag_cloud bora-widget">
            <h3 className="bora-widget-title">Tag Cloud</h3>
            <div className="tagcloud">
              <a
                href="#"
                className="tag-cloud-link tag-link-18 tag-link-position-1"
                style={{ fontSize: '8pt' }}
                aria-label="Article (1 item)"
              >
                Article
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-19 tag-link-position-2"
                style={{ fontSize: '8pt' }}
                aria-label="Building (1 item)"
              >
                Building
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-20 tag-link-position-3"
                style={{ fontSize: '16.75pt' }}
                aria-label="Constructions (3 items)"
              >
                Constructions
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-21 tag-link-position-4"
                style={{ fontSize: '22pt' }}
                aria-label="Industry (5 items)"
              >
                Industry
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-22 tag-link-position-5"
                style={{ fontSize: '22pt' }}
                aria-label="Metal (5 items)"
              >
                Metal
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-23 tag-link-position-6"
                style={{ fontSize: '16.75pt' }}
                aria-label="Mining (3 items)"
              >
                Mining
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-24 tag-link-position-7"
                style={{ fontSize: '19.666666666667pt' }}
                aria-label="Nature (4 items)"
              >
                Nature
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-25 tag-link-position-8"
                style={{ fontSize: '13.25pt' }}
                aria-label="News (2 items)"
              >
                News
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-26 tag-link-position-9"
                style={{ fontSize: '8pt' }}
                aria-label="Oil (1 item)"
              >
                Oil
              </a>{' '}
              <a
                href="#"
                className="tag-cloud-link tag-link-27 tag-link-position-10"
                style={{ fontSize: '22pt' }}
                aria-label="Polymer (5 items)"
              >
                Polymer
              </a>
            </div>
          </div>
          <div
            id="gdlr-core-recent-portfolio-widget-2"
            className="widget widget_gdlr-core-recent-portfolio-widget bora-widget"
          >
            <h3 className="bora-widget-title">Recent Cases</h3>
            <div className="gdlr-core-recent-portfolio-widget-wrap clearfix">
              <div className="gdlr-core-recent-portfolio-widget gdlr-core-media-image">
                <a href="#">
                  <img src={image} alt width={150} height={150} />
                  <span className="gdlr-core-image-overlay ">
                    <i className="gdlr-core-image-overlay-icon icon_link_alt gdlr-core-size-15" />
                  </span>
                </a>
              </div>
              <div className="gdlr-core-recent-portfolio-widget gdlr-core-media-image">
                <a href="#">
                  <img src={image} alt width={150} height={150} />
                  <span className="gdlr-core-image-overlay ">
                    <i className="gdlr-core-image-overlay-icon icon_link_alt gdlr-core-size-15" />
                  </span>
                </a>
              </div>
              <div className="gdlr-core-recent-portfolio-widget gdlr-core-media-image">
                <a href="#">
                  <img src={image} alt width={150} height={150} />
                  <span className="gdlr-core-image-overlay ">
                    <i className="gdlr-core-image-overlay-icon icon_link_alt gdlr-core-size-15" />
                  </span>
                </a>
              </div>
              <div className="gdlr-core-recent-portfolio-widget gdlr-core-media-image">
                <a href="#">
                  <img src={image} alt width={150} height={150} />
                  <span className="gdlr-core-image-overlay ">
                    <i className="gdlr-core-image-overlay-icon icon_link_alt gdlr-core-size-15" />
                  </span>
                </a>
              </div>
              <div className="gdlr-core-recent-portfolio-widget gdlr-core-media-image">
                <a href="#">
                  <img src={image} alt width={150} height={150} />
                  <span className="gdlr-core-image-overlay ">
                    <i className="gdlr-core-image-overlay-icon icon_link_alt gdlr-core-size-15" />
                  </span>
                </a>
              </div>
              <div className="gdlr-core-recent-portfolio-widget gdlr-core-media-image">
                <a href="#">
                  <img src={image} alt width={150} height={150} />
                  <span className="gdlr-core-image-overlay ">
                    <i className="gdlr-core-image-overlay-icon icon_link_alt gdlr-core-size-15" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const image = require('../assets/images/pexels-photo-530024-900x500.jpg')
    return (
      <Layout>
        <Hero
          title="Insights into our findings"
          description="Welcome to our blog, find out how we can grow each other"
        />
        <div className="gdlr-core-page-builder-body">
          <div className="gdlr-core-pbf-sidebar-wrapper ">
            <div className="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
              <div className="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-left">
                <div
                  className="gdlr-core-pbf-background-wrap"
                  style={{ backgroundColor: '#f7f7f7' }}
                />
                <div className="gdlr-core-pbf-sidebar-content-inner">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-blog-item gdlr-core-item-pdb clearfix  gdlr-core-style-blog-full-with-frame"
                      style={{ paddingBottom: 40 }}
                    >
                      <div
                        className="gdlr-core-blog-item-holder gdlr-core-js-2 clearfix"
                        data-layout="fitrows"
                      >
                        <BlogPostCard />
                        <BlogPostCard />
                        <BlogPostCard />
                        <BlogPostCard />
                      </div>
                      <Pagination />
                    </div>
                  </div>
                </div>
              </div>
              {this.renderSideBar()}
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  renderOption() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Insights into our findings"
            description="Welcome to our blog, find out how we can grow each other"
          />
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: rhythm(40),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
          >
            {posts.map(({ node }) => {
              const title = get(node, 'frontmatter.title') || node.fields.slug
              return (
                <div key={node.fields.slug}>
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                  <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </div>
              )
            })}
            <Bio />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
          }
        }
      }
    }
  }
`
