import React, { Component } from 'react'
import { Link } from 'gatsby'
export default class BlogPostCard extends Component {
  render() {
    const image = require('../../assets/images/jeroen-den-otter-661248-unsplash.jpg')

    return (
      <div className="gdlr-core-item-list gdlr-core-blog-full  gdlr-core-item-pdlr gdlr-core-style-left">
        <div className="gdlr-core-blog-thumbnail gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
          <a href="#">
            <img src={image} alt width={900} height={500} />
            <div className="gdlr-core-sticky-banner gdlr-core-title-font">
              <i className="fa fa-bolt" />Sticky Post
            </div>
          </a>
        </div>
        <div className="gdlr-core-blog-full-frame gdlr-core-skin-e-background">
          <div className="gdlr-core-blog-full-head clearfix">
            <div className="gdlr-core-blog-full-head-right">
              <h3
                className="gdlr-core-blog-title gdlr-core-skin-title"
                style={{ fontSize: 38, fontWeight: 600, letterSpacing: 0 }}
              >
                <a href="#">This Is An Awesome Sticky Post</a>
              </h3>
              <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                  <span className="gdlr-core-head">
                    <i className="icon_clock_alt" />
                  </span>
                  <a href="#">June 6, 2016</a>
                </span>
                <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                  <span className="gdlr-core-head">
                    <i className="icon_documents_alt" />
                  </span>
                  <a href="#" title="Posts by John Smith" rel="author">
                    John Smith
                  </a>
                </span>
                <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category">
                  <span className="gdlr-core-head">
                    <i className="icon_folder-alt" />
                  </span>
                  <a href="#" rel="tag">
                    Blog
                  </a>
                </span>
                <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-comment-number">
                  <span className="gdlr-core-head">
                    <i className="icon_comment_alt" />
                  </span>
                  <a href="#">3 </a>
                </span>
              </div>
            </div>
          </div>
          <div className="gdlr-core-blog-content">
            A wonderful serenity has taken possession of my entire soul, like
            these sweet mornings of spring which I enjoy with my whole heart. I
            am alone, and feel the charm of existence in this spot, which was
            created for the bliss of souls like mine. I am so happy, my dear
            friend, so absorbed in the exquisite sense of mere tranquil
            existence, that I neglect...
            <div className="clear" />
            <a
              className="gdlr-core-excerpt-read-more gdlr-core-button gdlr-core-rectangle"
              href="#"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    )
  }
}
