import React, { Component } from 'react'

export default class Pagination extends Component {
  render() {
    return (
      <div className="gdlr-core-pagination  gdlr-core-style-round gdlr-core-left-align gdlr-core-item-pdlr">
        <span aria-current="page" className="page-numbers current">
          1
        </span>{' '}
        <a className="page-numbers" href="page/2/index.html">
          2
        </a>{' '}
        <a className="page-numbers" href="page/3/index.html">
          3
        </a>
        <a className="next page-numbers" href="page/2/index.html" />
      </div>
    )
  }
}
